
.product-list-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 20px;
}

.search-bar-container {
  display: flex;
  align-items: center;
  width: 100%;
  max-width: 400px;
  margin-bottom: 20px;
}

.search-bar {
  flex: 1;
  padding: 10px;
  font-size: 16px;
  border: 1px solid #ddd;
  border-radius: 4px;
}

body.dark-mode {
  background-color: #121212;
  color: #ffffff;
}

.dark-mode .card {
  background-color: #1e1e1e;
  color: #ffffff;
  border-color: #333333;
}

.dark-mode .card .card-price.old-price {
  color: #aaaaaa;
}

.dark-mode .search-bar {
  background-color: #333333;
  color: #ffffff;
  border-color: #444444;
}

.dark-mode .search-bar::placeholder {
  color: #aaaaaa;
}

.dark-mode .mode-toggle-button {
  background-color: #444444;
  color: #ffffff;
  border: 1px solid #555555;
}

body {
  transition: background-color 0.3s, color 0.3s;
}

.product-list-container {
  transition: background-color 0.3s, color 0.3s;
}

.mode-toggle-button {
  padding: 10px 20px;
  background-color: #eeeeee;
  color: #333333;
  border: 1px solid #cccccc;
  cursor: pointer;
  border-radius: 5px;
}

.mode-toggle-button:hover {
  background-color: #dddddd;
}

.mode-toggle-button .fa-icon {
  margin-right: 8px;
}

body.dark-mode {
  background-color: #121212;
  color: #ffffff;
}

.dark-mode .card {
  background-color: #1e1e1e;
  color: #ffffff;
  border-color: #333333;
}

.dark-mode .card .card-price.old-price {
  color: #aaaaaa;
}

.dark-mode .search-bar {
  background-color: #333333;
  color: #ffffff;
  border-color: #444444;
}

.dark-mode .search-bar::placeholder {
  color: #aaaaaa;
}

.dark-mode .mode-toggle-button {
  background-color: #444444;
  color: #ffffff;
  border: 1px solid #555555;
}

body {
  transition: background-color 0.3s, color 0.3s;
}

.product-list-container {
  transition: background-color 0.3s, color 0.3s;
}

.mode-toggle-button {
  padding: 10px 20px;
  background-color: #eeeeee;
  color: #333333;
  border: 1px solid #cccccc;
  cursor: pointer;
  border-radius: 5px;
}

.mode-toggle-button:hover {
  background-color: #dddddd;
}

.filter-container {
  display: flex;
  align-items: center;
  gap: 10px;
}

.filter-item {
  display: flex;
  align-items: center;
  gap: 5px;
}

.filter-button {
  padding: 10px 20px;
  background-color: #eeeeee;
  color: #333333;
  border: 1px solid #cccccc;
  cursor: pointer;
  border-radius: 5px;
}

.filter-button:hover {
  background-color: #dddddd;
}


.card {
  position: relative; /* Torna o card um contexto de posicionamento para os elementos filhos */
  border: 1px solid #ccc;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  width: 100%; /* Defina a largura como 100% */
  max-width: 250px; /* Defina a largura máxima desejada */
  max-height: 800px;
}

.card-image {
  width: 100%; /* Defina a largura como 100% */
  max-height: 200px; /* Defina uma altura máxima para evitar que a imagem ultrapasse o contêiner */
  object-fit: scale-down; /* Redimensiona a imagem enquanto mantém sua proporção e preenche o contêiner */

}

.card-overlay {
  position: absolute; /* Torna o overlay um contexto de posicionamento */
  top: 0; /* Alinha o overlay ao topo do card */
  left: 0; /* Alinha o overlay à esquerda do card */
  width: 100%; /* Define a largura como 100% */
  height: 100%; /* Define a altura como 100% */
  background-color: rgba(0, 0, 0, 0.5); /* Adiciona um fundo semi-transparente */
  color: white; /* Define a cor do texto */
  display: flex; /* Usa flexbox para centralizar verticalmente o texto */
  align-items: center; /* Centraliza verticalmente o texto */
  justify-content: center; /* Centraliza horizontalmente o texto */
}

.card-title {
  font-size: 18px;
  padding: 10px; /* Adiciona espaçamento interno */
  margin-bottom: 20px;
  text-align: center; /* Centraliza o texto */
  
  
}

.card-content {
  padding: 10px; /* Adiciona espaçamento interno */
}
.menu a {
  text-decoration: none; /* Remove o sublinhado */
  color: inherit; /* Mantém a cor padrão do texto */
  /* Adicione outros estilos de acordo com sua preferência */
}

.menu a:hover {
  /* Estilos de hover (quando o mouse está sobre o link) */
  color: #00cc00
  ; /* Por exemplo, mude a cor quando o mouse estiver sobre o link */
}

.card-price {
  font-size: 15px;
  margin-bottom: 20px;
}

.card-reviews,
.card-stars {
  font-size: 5px;
  margin-bottom: 5px;
}

.card-link {
  display: block;
  font-size: 14px;
  color: blue;
  text-decoration: none;
}

.card-link:hover {
  text-decoration: underline;
}

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  padding: 0 15vw;
}


h1 {
  margin-bottom: 20px;
}

.product-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 5px;
}

.card {
  margin-bottom: 18px;
  padding: 15
  px;
}

.card .old-price {
  text-decoration: line-through;
  color: #888;
  position: absolute;
  bottom: -1px;
  right: 10px; /* Ajuste a posição para a direita */
}

.card .new-price {
  font-size: 22px;
  font-weight: bold;
  color: #00cc00;
  position: absolute;
  bottom: -5px;
  left: 20px; /* Ajuste a posição esquerda conforme necessário */
}

:root {
  --star-size: 20px;
  --star-color: #fff;
  --star-background: #fc0;
}
.icon{
  width: 25px;
  height: 25px;
  position: absolute; /* Posicionando o wrapper do ícone absolutamente */
  top: 50%; /* Definindo o topo em 50% */
  left: 50%; /* Definindo a esquerda em 50% */
  transform: translate(420%, 50%); /* Movendo o ícone para o centro */
  object-fit: scale-down;

}

.Stars {
  --percent: calc(var(--rating) / 5 * 100%);
  
  display: inline-block;
  font-size: var(--star-size);
  font-family: Times; 
  line-height: 1;
  margin-left: 5px;
  margin-top: 5px;
  margin-bottom: 10px;
  
  
  &::before {
      content: '★★★★★';
      letter-spacing: 2px;
      background: linear-gradient(90deg, var(--star-background) var(--percent), var(--star-color) var(--percent));
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      
  }
}

.stars-and-reviews {
  display: flex;
  align-items: center; /* Alinha os itens verticalmente */
  
}


.c-loader {
  animation: is-rotating 1s infinite;
  border: 6px solid #e5e5e5;
  border-radius: 50%;
  border-top-color: #eea849;
  height: 50px;
  width: 50px;
}

@keyframes is-rotating {
  to {
    transform: rotate(1turn);
  }
}
.reviews {
  margin-left: auto; /* Move o número de reviews para a direita */
  margin-right: 10px;

}
.discount-label {
  position: absolute;
  top: 50px;
  right: 10px;
  background-color: #ff0000;
  color: #fff;
  padding: 5px 10px;
  border-radius: 5px;
}

@media (max-width: 1024px) {
  .product-list {
    justify-content: space-between;
  }
  .card-title {
    font-size: 14px;
    text-align: center; /* Centraliza o texto */
  }
  .card-image {
    width: 100%; /* Defina a largura como 100% */
    max-height: 150px; /* Defina uma altura máxima para evitar que a imagem ultrapasse o contêiner */
    object-fit: scale-down; /* Redimensiona a imagem enquanto mantém sua proporção e preenche o contêiner */
  
  }

  .card {
    margin-bottom: 0px;
  }
  
  .container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
    padding: 0 0vw;
  }
  .card-content {
    padding: 0px; /* Adiciona espaçamento interno */
  }
  .card {
    margin-bottom: 0px;
  }

  .card {
    width: calc(33% - 1px); /* Calcula a largura dos cartões para formar 2 colunas */
    max-width: calc(33% - 1px); /* Defina a largura máxima dos cartões */
    margin-right: 0; /* Remova a margem direita */
    margin-left: 0; /* Remova a margem esquerda */
    margin-bottom: 1px; /* Adicione margem inferior para espaçamento entre linhas */
  }
}

@media (max-width: 1400px) {
  .product-list {
    justify-content: space-between;
  }
  .card-title {
    font-size: 14px;
    text-align: center; /* Centraliza o texto */
  }
  .card-image {
    width: 100%; /* Defina a largura como 100% */
    max-height: 150px; /* Defina uma altura máxima para evitar que a imagem ultrapasse o contêiner */
    object-fit: scale-down; /* Redimensiona a imagem enquanto mantém sua proporção e preenche o contêiner */
  
  }

  .card {
    margin-bottom: 0px;
  }
  
  .container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
    padding: 0 0vw;
  }
  .card-content {
    padding: 0px; /* Adiciona espaçamento interno */
  }
  .card {
    margin-bottom: 0px;
  }

  .card {
    width: calc(22% - 1px); /* Calcula a largura dos cartões para formar 2 colunas */
    max-width: calc(22% - 1px); /* Defina a largura máxima dos cartões */
    margin-right: 0; /* Remova a margem direita */
    margin-left: 0; /* Remova a margem esquerda */
    margin-bottom: 1px; /* Adicione margem inferior para espaçamento entre linhas */
  }
}

@media (max-width: 1024px) {
  .product-list {
    justify-content: space-between;
  }
  .card-title {
    font-size: 14px;
    text-align: center; /* Centraliza o texto */
  }
  .card-image {
    width: 100%; /* Defina a largura como 100% */
    max-height: 150px; /* Defina uma altura máxima para evitar que a imagem ultrapasse o contêiner */
    object-fit: scale-down; /* Redimensiona a imagem enquanto mantém sua proporção e preenche o contêiner */
  
  }

  .card {
    margin-bottom: 0px;
    padding: 20px;
  }
  
  .container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
    padding: 0 0vw;
  }
  .card-content {
    padding: 0px; /* Adiciona espaçamento interno */
  }
  .card {
    margin-bottom: 0px;
  }

  .card {
    width: calc(25% - 1px); /* Calcula a largura dos cartões para formar 2 colunas */
    max-width: calc(245% - 1px); /* Defina a largura máxima dos cartões */
    margin-right: 0; /* Remova a margem direita */
    margin-left: 0; /* Remova a margem esquerda */
    margin-bottom: 1px; /* Adicione margem inferior para espaçamento entre linhas */
  }

  .reviews {
    margin-left: 30px;
  }

  .span-review {
    position: absolute;
    top: 10px;
  }

  .Stars {
    margin-left: -10px;
    margin-bottom: 40px;
  }
}

@media (max-width: 600px) {
  .product-list {
    justify-content: space-between;
  }
  .card-image {
    width: 100%; /* Defina a largura como 100% */
    max-height: 150px; /* Defina uma altura máxima para evitar que a imagem ultrapasse o contêiner */
    object-fit: scale-down; /* Redimensiona a imagem enquanto mantém sua proporção e preenche o contêiner */
  
  }
  .card-title {
    font-size: 14px;
    text-align: center; /* Centraliza o texto */
  }

  .card-content {
    padding: 0px; /* Adiciona espaçamento interno */
  }

  .reviews{
    font-size: 13px;
    font-weight: bold
  }
  .old-price{
    font-size: 10px;
  }


  .container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
    margin-bottom: 0px;
    padding: 0 0vw;
  }

  .card {
    width: calc(50% - 1px); /* Calcula a largura dos cartões para formar 2 colunas */
    max-width: calc(50% - 1px); /* Defina a largura máxima dos cartões */
    width: 50%;
    margin-right: 0; /* Remova a margem direita */
    margin-left: 0; /* Remova a margem esquerda */
    margin-bottom: 1px; /* Adicione margem inferior para espaçamento entre linhas */
  }
}
#loadMore {
  width: 200px;
  color: #fff;
  display: block;
  margin: 10px auto; /* Isso centraliza horizontalmente */
  padding: 10px;
  border-radius: 10px;
  border: 1px solid transparent;
  background: linear-gradient(to left, #f46b45, #eea849);
  font-size: 15px;
  font-weight: bold;
  transition: .3s;
}
.load-more-container{
  align-items: center;
  align-content: center;
  align-self: center;
  margin: 10px auto;
}

body.dark-mode {
  background-color: #121212;
  color: #ffffff;
}

.dark-mode .card {
  background-color: #1e1e1e;
  color: #ffffff;
  border-color: #333333;
}

.dark-mode .card .card-price.old-price {
  color: #aaaaaa;
}

.dark-mode .search-bar {
  background-color: #333333;
  color: #ffffff;
  border-color: #444444;
}

.dark-mode .search-bar::placeholder {
  color: #aaaaaa;
}

.dark-mode .mode-toggle-button {
  background-color: #444444;
  color: #ffffff;
  border: 1px solid #555555;
}

body {
  transition: background-color 0.3s, color 0.3s;
}

.product-list-container {
  transition: background-color 0.3s, color 0.3s;
}

.mode-toggle-button {
  padding: 10px 20px;
  background-color: #eeeeee;
  color: #333333;
  border: 1px solid #cccccc;
  cursor: pointer;
  border-radius: 5px;
}

.mode-toggle-button:hover {
  background-color: #dddddd;
}

.filter-container {
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 10px;
}

.filter-item {
  display: flex;
  align-items: center;
  gap: 5px;
}

.filter-button {
  padding: 10px 20px;
  background-color: #eeeeee;
  color: #333333;
  border: 1px solid #cccccc;
  cursor: pointer;
  border-radius: 5px;
}

.filter-button.active {
  background-color: #cccccc;
}

.filter-button:hover {
  background-color: #dddddd;
}


body.dark-mode {
  background-color: #121212;
  color: #ffffff;
}

.dark-mode .card {
  background-color: #1e1e1e;
  color: #ffffff;
  border-color: #333333;
}

.dark-mode .card .card-price.old-price {
  color: #aaaaaa;
}

.dark-mode .search-bar {
  background-color: #333333;
  color: #ffffff;
  border-color: #444444;
}

.dark-mode .search-bar::placeholder {
  color: #aaaaaa;
}

.dark-mode .mode-toggle-button {
  background-color: #444444;
  color: #ffffff;
  border: 1px solid #555555;
}

body {
  transition: background-color 0.3s, color 0.3s;
}

.product-list-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 20px;
  transition: background-color 0.3s, color 0.3s;
}

.search-bar-container {
  display: flex;
  align-items: center;
  width: 100%;
  max-width: 100%;
  margin-bottom: 20px;
}

.search-bar {
  flex: 1;
  padding: 10px;
  font-size: 16px;
  border: 1px solid #ddd;
  border-radius: 4px;

}

.mode-toggle-button {
  padding: 5px;
  width: 40px;
  height: 40px;
  background-color: #eeeeee;
  color: #333333;
  border: 1px solid #cccccc;
  cursor: pointer;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: auto;
}

.mode-toggle-button:hover {
  background-color: #dddddd;
}

.filter-container {
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 10px;
}

.filter-item {
  display: flex;
  align-items: center;
  gap: 5px;
}

.price-filters {
  display: flex;
  gap: 10px;
}

.filter-button {
  padding: 10px 20px;
  background-color: #eeeeee;
  color: #333333;
  border: 1px solid #cccccc;
  cursor: pointer;
  border-radius: 10px;
}

.filter-button.active {
  background-color: #85e26d;
}

.filter-button:hover {
  background-color: #dddddd;
}

/* Media Queries */
@media (max-width: 1024px) {
  .search-bar-container {
    flex-direction: column;
    align-items: flex-start;
  }

  .filter-container {
    flex-wrap: wrap;
    gap: 5px;
  }

  .price-filters {
    flex-direction: column;
    gap: 5px;
  }

  .filter-item,
  .filter-button,
  .mode-toggle-button {
    width: 100%;
  }

  .search-bar {
    width: 100%;
    margin-bottom: 10px;
  }
}

@media (max-width: 600px) {
  .search-bar-container {
    flex-direction: column;
    align-items: flex-start;
  }

  .filter-container {
    flex-wrap: wrap;
    gap: 5px;
  }

  .price-filters {
    flex-direction: column;
    gap: 5px;
  }

  .filter-item,
  .filter-button,
  .mode-toggle-button {
    width: 100%;
  }

  .search-bar {
    width: 100%;
    margin-bottom: 10px;
  }
}
